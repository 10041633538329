<template>
  <div>
    <div class="row">
      <div class="col-xl-4">
        <el-date-picker
            v-model="period"
            type="datetimerange"
            range-separator="To"
            start-placeholder="Начало период"
            end-placeholder="Конец периода">
        </el-date-picker>
      </div>
      <div class="col-xl-3">
          <el-input v-model="form.analyseCodes" placeholder="Коды анализов" />
      </div>
      <div class="col-xl-3">
          <el-checkbox v-model="form.isAgreement" label="Только с заполнеными договорами"></el-checkbox>
      </div>
      <div class="col-xl-3">
          <el-checkbox v-model="form.isDeleteAllAfterChar" label="Удалить все начиная с символа /"></el-checkbox>
      </div>
      <div class="col-xl-2">
        <el-button @click="handlerDownload">
          Export
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {ElButton, ElInput, ElDatePicker, ElCheckbox } from "element-plus";
export default {
  name: 'EHealthResultReport',
  components: {
    ElButton,
    ElInput,
    ElDatePicker,
    ElCheckbox,
  },
  data: function() {
    return {
        period: null,
        form: {
          dateFrom: null,
          dateTo: null,
          analyseCodes: '',
          isAgreement: false,
          isDeleteAllAfterChar: false,
        }
    };
  },
  methods: {
    handlerDownload() {
      if (this.period != null) {
        this.form.dateFrom = this.convertDate(this.period[0]);
        this.form.dateTo = this.convertDate(this.period[1]);
          window.open(`/api/ehealthResultReport/export?dateFrom=${this.form.dateFrom}&dateTo=${this.form.dateTo}&analysCodes=${this.form.analyseCodes}&isAgreement=${this.form.isAgreement}&isDeleteAllAfterChar=${this.form.isDeleteAllAfterChar}`, '_blank');
      }
    },
    convertDate(d) {
        let month = String(d.getMonth() + 1);
        let day = String(d.getDate());
        const year = String(d.getFullYear());
        let hours = String(d.getHours()).padStart(2, '0');
        let minutes = String(d.getMinutes()).padStart(2, '0');
        let seconds = String(d.getSeconds()).padStart(2, '0');

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    }
  }
}
</script>